@import url("https://fonts.googleapis.com/css?family=Quicksand");
* {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
}

@media screen and (max-width: 900px) {
  figure figcaption {
    width: 100%;
  }
}

#page {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.qualification_img {
  width: 70px;
  border-radius: 20px;
  margin: 5px;
}